import styled from 'styled-components';

export const StyledTable = styled.table`
	border-collapse: collapse;
	border-color: ${(props) => props.theme.borderColor};
	font-family: monospace;
	font-size: 0.9em;
	margin-bottom: 16px;
	tr:nth-of-type(odd) {
		background-color: #f8f8f8;
	}

	td,
	th {
		border-spacing: 0;
		border-style: solid;
		border-width: 1px;
		padding: 4px;
		text-align: center;
	}
	@media all and (min-width: 800px) {
		td,
		th {
			padding: 8px;
		}
	}
`;
