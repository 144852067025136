import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { StyledArticle, StyledSelect } from '../../styles';

import { Layout } from '../../components/Layout';
import { MessageDiv } from '../../styles/formularz';
import { StyledH2 } from '../../styles/common';
import { StyledTable } from '../../styles/grafik';
import axios from '../../config/axios';
import { useAuth } from '../../hooks/useAuth';
import { SEO } from '../../components/SEO';

export function Head() {
	return <SEO title="Grafik służb" description="Grafik służb." />;
}

const isBrowser = () => typeof window !== 'undefined';

const monthNames = [
	'styczeń',
	'luty',
	'marzec',
	'kwiecień',
	'maj',
	'czerwiec',
	'lipiec',
	'sierpień',
	'wrzesień',
	'październik',
	'listopad',
	'grudzień',
];

const dayNames = ['ND', 'PN', 'WT', 'ŚR', 'CZ', 'PI', 'SO'];

const minutesToHour = (minutes: number) => {
	const h = Math.floor(minutes / 60);
	const m = minutes - h * 60;
	const sh = `0${h}`;
	const sm = `0${m}`;
	return `${sh.slice(-2)}:${sm.slice(-2)}`;
};

function GrafikPage() {
	const [selectedMonth, setSelectedMonth] = useState(0); // 0 - current, 1 - next
	const [data, setData] = useState<any>([]);
	const [isMobile, setIsMobile] = useState(false);
	const auth = isBrowser()
		? useAuth()
		: { user: '', role: '', verifyUser: () => {}, signOut: () => {} };

	const handleOnResize = () => {
		setIsMobile(isBrowser() ? window.innerWidth < 800 : false);
	};

	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('resize', handleOnResize);
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('resize', handleOnResize);
			}
		};
	}, []);

	useEffect(() => {
		auth.verifyUser();
		const token = localStorage.getItem('token');
		if (!token) {
			navigate('/konto/logowanie/');
		}
	}, []);

	useEffect(() => {
		if (auth.user) {
			try {
				(async () => {
					const response = await axios.get('/get_schedule.php');
					if (response?.status === 200) {
						setData(response.data);
					}
				})();
			} catch (e) {}
		}
	}, [auth.user]);

	if (!auth.user) {
		return null;
	}

	const dt = new Date();
	const month = dt.getMonth() + 1;
	const year = dt.getFullYear();
	let nextYear = year;
	const daysInMonth = new Date(year, month, 0).getDate();

	let nextMonth = month + 1;

	if (month === 12) {
		nextMonth = 1;
		nextYear++;
	}
	const daysInNextMonth = new Date(year, nextMonth, 0).getDate();

	const generateSchedule = () => {
		if (data.length === 0) {
			return;
		}
		const response: any = [];
		const scheduleMonth = selectedMonth ? nextMonth : month;
		const scheduleYear = selectedMonth ? nextYear : year;
		const scheduleDays: number = selectedMonth ? daysInNextMonth : daysInMonth;
		for (let i = 1; i <= scheduleDays; i++) {
			const d = `0${i}`;
			const m = `0${scheduleMonth}`;
			const date = `${scheduleYear}-${m.slice(-2)}-${d.slice(-2)}`;
			const dateO = new Date(scheduleYear, scheduleMonth - 1, i);
			const scheduleDay = dateO.getDay();

			const job = () => {
				const jobResponse = [];

				for (let j = 0; j < data.length; j++) {
					const dayDate = data[j].data?.substr(0, 10);
					if (dayDate === date) {
						jobResponse.push(
							<span key={`d${i}p${j}`}>
								{jobResponse.length > 0 ? <br /> : null}
								<span>{minutesToHour(data[j].czasOd)}</span> -{' '}
								<span>{minutesToHour(data[j].czasDo)}</span>{' '}
								<span>{data[j].opis ? `[${data[j].opis.trim()}]` : null}</span>
							</span>
						);
					}
				}
				return jobResponse;
			};

			response.push(
				<tr key={`m${m}d${d}`}>
					<td>{isMobile ? date.slice(-2).replace(' ', '-') : date}</td>
					<td>{dayNames[scheduleDay]}</td>
					<td>{job()}</td>
					<br />
				</tr>
			);
		}
		return response;
	};

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Grafik służb {auth.user}
				</StyledH2>
				<MessageDiv>
					<label htmlFor="selectMonth">Wybierz miesiąc:</label>
					<StyledSelect
						id="selectMonth"
						defaultValue={selectedMonth}
						onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
					>
						<option value="0">
							{monthNames[month - 1]} {year}
						</option>
						<option value="1">
							{monthNames[nextMonth - 1]} {nextYear}
						</option>
					</StyledSelect>
					<hr />
					<StyledTable>
						<thead>
							<tr>
								<th>DATA</th>
								<th>{isMobile ? 'DT.' : 'DZIEŃ TYG.'}</th>
								<th>OD - DO</th>
							</tr>
						</thead>
						<tbody>{generateSchedule()}</tbody>
					</StyledTable>
					<span>
						Mimo dołożenia wszelkich starań nie gwarantujemy, że publikowane
						dane nie zawierają błedów. Ponieważ ew. błędy nie mogą jednak być
						podstawą do roszczeń, w&nbsp;przypadku wątpliwości prosimy o kontakt
						z osobą odpowiedzialną za tworzenie grafiku.
					</span>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
}

export default GrafikPage;
